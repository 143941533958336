import { AlertBanner } from "@atoms";
import { RecurrenceFrequency } from "@constants/choices";
import HorizontalLayout from "@legacy/core/components/HorizontalLayout";
import Spinner from "@legacy/core/components/Spinner";
import AdvancedMultiSelectField from "@legacy/core/fields/AdvancedMultiSelectField";
import BooleanField from "@legacy/core/fields/BooleanField";
import IntegerField from "@legacy/core/fields/IntegerField";
import DateTimeCell from "@organisms/Table/TableCells/DateTimeCell/DateTimeCell";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import pluralize from "pluralize";
import { Component, Fragment } from "react";
import ClientSearchOrCreateSelect, { clientToClientOption } from "../../clients/inputs/ClientSearchOrCreateSelect";
import ServiceLocationSearchOrCreateSelect, { serviceLocationToServiceLocationOption } from "../../clients/inputs/ServiceLocationSearchOrCreateSelect";
import { renderClientString, renderServiceLocationString } from "../../clients/utils/utils";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import Banner from "../../core/components/Banner";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import BasicSelectField from "../../core/fields/BasicSelectField";
import CharField from "../../core/fields/CharField";
import DateField from "../../core/fields/DateField";
import DurationField from "../../core/fields/DurationField";
import GeneratedDatetimeField from "../../core/fields/GeneratedDatetimeField";
import LinkedObjectsDisplayField from "../../core/fields/LinkedObjectsDisplayField";
import SearchCreateSelectField from "../../core/fields/SearchCreateSelectField";
import SwitchField from "../../core/fields/SwitchField";
import { JobOriginTypes } from "../../core/utils/enums";
import { valueIsDefined } from "../../core/utils/utils";
import EstimateObjectLink from "../../estimates/components/EstimateObjectLink";
import { calculateEstimateAmounts } from "../../estimates/utils/utils";
import PriceBookServiceSearchOrCreateSelect, { priceBookServiceToPriceBookServiceOption } from "../../pricebook/inputs/PriceBookServiceSearchOrCreateSelect";
import JobObjectLink from "../components/JobObjectLink";
import AssignedTechniciansField from "../fields/AssignedTechniciansField";
import { getDefaultAssignedTechnicians, getDefaultRecurrenceWeekdays } from "../utils/utils";
import JobEditableDetailsFields from "./fragments/JobEditableDetailsFields";
dayjs.extend(advancedFormat)


const FORM_MODE_CAPTIONS = {
    ADD_JOB: "Please provide the following information to create a job:",
    EDIT_JOB: "Use the fields below to edit this job:",
}

async function fetchSeriesData(currentUser, job) {
    const endpoint = DjangoUrls["jobs:api-jobs-list-series-dates"](currentUser.service_company.slug)

    const headers = new Headers()
    headers.append("X-CSRFToken", Cookies.get("csrftoken"))
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")

    const data = {
        "recurrence_enabled": job.recurrence_enabled,
        "estimated_arrival_time": job.estimated_arrival_time,
        "recurrence_end": job.recurrence_end,
        "recurrence_interval": job.recurrence_interval,
        "recurrence_frequency": job.recurrence_frequency,
        "weekly_recurrence": job.weekly_recurrence,
        "monthly_recurrence": parseInt(job.monthly_recurrence),
    }

    const response = await fetch(endpoint, { method: "POST", headers: headers, body: JSON.stringify(data) })

    if (response.ok) {
        return await response.json()
    }
    else {
        return null
    }
}

function JobSeriesDates(props) {
    const {job} = props
    const currentUser = window.CURRENT_USER

    const { data, error, isLoading, isError } = useQuery({
        queryKey: ["series-data", job.recurrence_enabled, job.estimated_arrival_time, job.recurrence_end, job.recurrence_interval, job.recurrence_frequency, job.weekly_recurrence, job.monthly_recurrence],
        queryFn: () => fetchSeriesData(currentUser, job),
        staleTime: 60000,
    })

    if (isError) {
        console.error(error)
    }
    else if (data) {
        return (
            <div className="series_dates_banner">
                <AlertBanner
                    type="info"
                    size="md"
                    title="Jobs will be created for the following days and times:"
                    subtitle={
                        <ul className="series_dates">
                            <li key="series_date_ETA" className="series_date"><DateTimeCell value={job.estimated_arrival_time} showTime={true} /></li>
                            {data.map((date, index) => <li key={`series_date_${index}`} className="series_date"><DateTimeCell value={date} showTime={true} /></li>)}
                        </ul>
                    }
                />
            </div>
        )
    }

}


class JobForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            clientSearchInput: "",
            serviceLocationSearchInput: "",
            serviceSearchInput: "",

            currencyCode: window.CURRENCY_CODE,
            languageCode: window.LANGUAGE_CODE,
        }
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {
            mode,
            submitting,
            job,
            estimate,
            callbackTo,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateClientSelection,
            updateServiceLocationSelection,
            updatePriceBookServiceSelection,
            updateEquipmentSelection,
            updateReporterSelection,
            updatePointOfContactSelection,
            workingTechnicianOptions,
            selectedClient,
            selectedServiceLocation,
            selectedPriceBookService,
            selectedEquipment,
            selectedReporter,
            selectedPointOfContact,
            showEquipmentWipedWarning,
            showContactsWipedWarning,
            similarJobs,
            outstandingBalance,
            jobOriginTypeOptions,
            allJobOriginTypes,
            preferredTimezone,
            showJobOriginFields,
            showCustomJobIDField,
            defaultCountryCode,
            formatCurrencyValue,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            returnScroll
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                if (mode === "ADD_JOB") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text= {job.recurrence_enabled? "Schedule Job Series" :"Schedule"} handler={event => requestAction("JOB_CREATE")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Preview Job" handler={event => requestAction("JOB_PREVIEW")} />
                                <UniversalButton type="secondary" text="Save Draft" handler={event => requestAction("JOB_CREATE_DRAFT")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else {
                    if (job.is_draft) {
                        return (
                            <ButtonGroup>
                                <ButtonGroupRow>
                                    <UniversalButton type="primary" text="Schedule" handler={event => requestAction("JOB_CREATE")} />
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Preview Job" handler={event => requestAction("JOB_PREVIEW")} />
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Save Draft" handler={event => requestAction("JOB_CREATE_DRAFT")} />
                                    <UniversalButton type="danger" text="Delete Draft" handler={event => requestAction("JOB_DELETE_DRAFT")} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                    else {
                        return (
                            <ButtonGroup>
                                <ButtonGroupRow>
                                    <UniversalButton type="primary" text="Save" handler={event => requestAction("JOB_UPDATE")} />
                                    <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("JOB_CANCEL_EDITS")} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                }
            }
        }
    }

    renderDownPaymentBanner = (estimate) => {
        const { formatCurrencyValue } = this.props
        const { downPaymentAmountDue, downPaymentAmountPaid } = calculateEstimateAmounts(estimate)

        if (downPaymentAmountDue === 0) {
            return <Banner type="success" text="The requested down payment for this estimate has been paid in full." extraMargin={true} />
        }
        else if (downPaymentAmountPaid !== 0) {
            return <Banner type="warning" text={`The requested down payment for this estimate has only been partially paid (${formatCurrencyValue(downPaymentAmountPaid)} of ${formatCurrencyValue(estimate.down_payment_amount)})`} extraMargin={true} />
        }
        else {
            return <Banner type="warning" text="The requested down payment for this estimate has not yet been paid." extraMargin={true} />
        }
    }

    render() {
        const {
            mode,
            submitting,
            job,
            estimate,
            callbackTo,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateClientSelection,
            updateServiceLocationSelection,
            updatePriceBookServiceSelection,
            updateEquipmentSelection,
            updateReporterSelection,
            updatePointOfContactSelection,
            workingTechnicianOptions,
            selectedClient,
            selectedServiceLocation,
            selectedPriceBookService,
            selectedEquipment,
            selectedReporter,
            selectedPointOfContact,
            showEquipmentWipedWarning,
            showContactsWipedWarning,
            similarJobs,
            outstandingBalance,
            jobOriginTypeOptions,
            allJobOriginTypes,
            preferredTimezone,
            showJobOriginFields,
            showCustomJobIDField,
            defaultCountryCode,
            formatCurrencyValue,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            returnScroll
        } = this.props

        const jobDoesNotHaveParent = Object.keys(estimate).length === 0 && (job.estimate === null || job.estimate === undefined) && Object.keys(callbackTo).length === 0 && (job.callback_to === null || job.callback_to === undefined)
        const jobWasStarted = (job.initial_started_time === null || job.initial_started_time === undefined)
        const allowClientDetailsEdit = jobWasStarted && jobDoesNotHaveParent
        const allowJobTypeEdit = jobDoesNotHaveParent

        const renderSpecialRecurrenceOptions = (frequency) => {
            if (frequency === RecurrenceFrequency.MONTHLY) {
                const date = dayjs(job.estimated_arrival_time)

                const day = date.format("D")
                const dayOfWeek = date.format("dddd")

                const firstOfMonth = date.startOf('month')
                const occurrence = Math.floor((date.date() - firstOfMonth.date()) / 7) + 1
                // We're leveraging dayjs to get the ordinal suffix for the occurrence.
                // It doesn't have anything to do with dates, just a handy util.
                const occurrenceFormatted = dayjs().date(occurrence).format("Do")

                const options = [
                  { value: "0", label: `Day ${day} of each month` },
                  { value: "1", label: `The ${occurrenceFormatted} ${dayOfWeek} of each month`}
                ]

              return options
            }
            else if (frequency === RecurrenceFrequency.WEEKLY) {
                return [
                    { value: 0, label: 'Monday' },
                    { value: 1, label: 'Tuesday' },
                    { value: 2, label: 'Wednesday' },
                    { value: 3, label: 'Thursday' },
                    { value: 4, label: 'Friday' },
                    { value: 5, label: 'Saturday' },
                    { value: 6, label: 'Sunday' },
                ]
            }
        }

        const weeklyRecurrenceOptions = renderSpecialRecurrenceOptions(RecurrenceFrequency.WEEKLY)
        const monthlyRecurrenceOptions = renderSpecialRecurrenceOptions(RecurrenceFrequency.MONTHLY)
        const frequencyOptions = [
            { value: RecurrenceFrequency.DAILY, label: `${pluralize("Day", job.recurrence_interval)}` },
            { value: RecurrenceFrequency.WEEKLY, label: `${pluralize("Week", job.recurrence_interval)}` },
            { value: RecurrenceFrequency.MONTHLY, label: `${pluralize("Month", job.recurrence_interval)}` },
            { value: RecurrenceFrequency.YEARLY, label: `${pluralize("Year", job.recurrence_interval)}` },
        ]

        let augmentedJobOriginTypeOptions = [...jobOriginTypeOptions]

        if (valueIsDefined(job.origin_type)) {
            const selectedOptionExists = jobOriginTypeOptions.find(option => option.value === parseInt(job.origin_type)) !== undefined

            if (!selectedOptionExists) {
                augmentedJobOriginTypeOptions.push(allJobOriginTypes.find(option => option.value === parseInt(job.origin_type)))
            }
        }

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Job Create/Update">
                    <div className="data-panel__form" aria-label="Job Create/Update Form">
                        <p className="data-panel__form__caption">{FORM_MODE_CAPTIONS[mode]}</p>
                        <fieldset>
                            <legend>Client Details</legend>
                            <SearchCreateSelectField
                                fieldName="client"
                                fieldLabel="Client"
                                fieldValue={selectedClient !== null ? renderClientString(selectedClient) : null}
                                inputComponent={
                                    <ClientSearchOrCreateSelect
                                        disabled={!allowClientDetailsEdit}
                                        onSelectionChange={selectedOption => updateClientSelection(selectedOption != null ? selectedOption.object : null)}
                                        onInputChange={(input, action) => {action.action === "input-change" && this.setState({clientSearchInput: input})}}
                                        defaultSelected={selectedClient !== null ? clientToClientOption(selectedClient) : null}
                                        showCreateButton={allowClientDetailsEdit}
                                        onCreateClick={event => switchToSecondaryForm("ADD_CLIENT", null, {name: this.state.clientSearchInput})}
                                        listEndpointKwargs={[
                                            ["is_active", true]
                                        ]}
                                    ></ClientSearchOrCreateSelect>
                                }
                                showButton={allowClientDetailsEdit && selectedClient !== null}
                                buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Client</Fragment>}
                                buttonAction={event => switchToSecondaryForm("EDIT_CLIENT", selectedClient, null)}
                                disabled={!allowClientDetailsEdit}
                                errors={errors}
                            ></SearchCreateSelectField>
                            {
                                selectedClient !== null && selectedClient.notes !== "" && (
                                    <BasicDisplayField
                                        fieldName="client_notes"
                                        fieldLabel="Client Notes"
                                        fieldValue={selectedClient.notes}
                                        indented={true}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                selectedClient !== null && selectedClient.credit_limit && outstandingBalance && (parseFloat(outstandingBalance) > parseFloat(selectedClient.credit_limit)) && (
                                    <Banner type="warning" text={<span>This client has exceeded their credit limit ({formatCurrencyValue(selectedClient.credit_limit)}) by {formatCurrencyValue(parseFloat(outstandingBalance) - parseFloat(selectedClient.credit_limit))}</span>} />
                                )
                            }
                            {
                                selectedClient !== null && (
                                    <SearchCreateSelectField
                                        fieldName="service_location"
                                        fieldLabel="Service Location"
                                        fieldValue={selectedServiceLocation !== null ? renderServiceLocationString(selectedServiceLocation) : null}
                                        inputComponent={
                                            <ServiceLocationSearchOrCreateSelect
                                                disabled={!allowClientDetailsEdit}
                                                onSelectionChange={selectedOption => updateServiceLocationSelection(selectedOption != null ? selectedOption.object : null)}
                                                onInputChange={(input, action) => {action.action === "input-change" && this.setState({serviceLocationSearchInput: input})}}
                                                defaultSelected={selectedServiceLocation !== null ? serviceLocationToServiceLocationOption(selectedServiceLocation) : null}
                                                client={selectedClient}
                                                showCreateButton={allowClientDetailsEdit}
                                                onCreateClick={event => switchToSecondaryForm("ADD_SERVICE_LOCATION", null, {physical_address_street: this.state.serviceLocationSearchInput})}
                                            ></ServiceLocationSearchOrCreateSelect>
                                        }
                                        showButton={allowClientDetailsEdit && selectedServiceLocation !== null}
                                        buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Service Location</Fragment>}
                                        buttonAction={event => switchToSecondaryForm("EDIT_SERVICE_LOCATION", selectedServiceLocation, null)}
                                        disabled={!allowClientDetailsEdit}
                                        errors={errors}
                                    ></SearchCreateSelectField>
                                )
                            }
                            {
                                selectedClient !== null && selectedServiceLocation !== null && selectedServiceLocation.notes !== "" && (
                                    <BasicDisplayField
                                        fieldName="service_location_notes"
                                        fieldLabel="Service Location Notes"
                                        fieldValue={selectedServiceLocation.notes}
                                        indented={true}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                showEquipmentWipedWarning && (
                                    <Banner type="warning" text="Changing the client details has cleared your equipment selection." />
                                )
                            }
                            {
                                showContactsWipedWarning && (
                                    <Banner type="warning" text="Changing the client details has cleared your job reporter and contact selections." />
                                )
                            }
                        </fieldset>
                        {
                            selectedClient !== null && selectedServiceLocation !== null && (
                                <fieldset>
                                    <legend>Job Details</legend>
                                    {
                                        estimate.id && !window.CURRENT_USER.hide_financial_context && (
                                            <Fragment>
                                                <LinkedObjectsDisplayField
                                                    fieldName="estimate"
                                                    fieldLabel="Created From Estimate"
                                                    objectComponentList={[<EstimateObjectLink key="parent_estimate_0" estimate={estimate} destination={DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, estimate.id)}></EstimateObjectLink>]}
                                                    fieldClasses={parseInt(estimate.down_payment_amount) !== 0 ? ["data-panel__form__field", "data-panel__form__field--no-margin"] : ["data-panel__form__field"]}
                                                ></LinkedObjectsDisplayField>
                                                {parseInt(estimate.down_payment_amount) !== 0 && this.renderDownPaymentBanner(estimate)}
                                            </Fragment>
                                        )
                                    }
                                    {
                                        showCustomJobIDField
                                        ?
                                        <CharField
                                            fieldName="custom_id"
                                            fieldLabel="Job ID"
                                            fieldValue={job.custom_id || ""}
                                            fieldOnChange={custom_id => onFormDataChange("custom_id", custom_id || "")}
                                            maxLength="15"
                                            errors={errors}
                                        ></CharField>
                                        :
                                        <div id="div_id_custom_id">{errors.custom_id && <Banner type="warning" text={errors.custom_id} extraMargin={true} />}</div>
                                    }
                                    {
                                        showJobOriginFields && (
                                            <BasicSelectField
                                                disabled={!allowClientDetailsEdit}
                                                fieldName="origin_type"
                                                fieldLabel="Job Source"
                                                fieldValue={job.origin_type}
                                                fieldOnChange={origin_type => onFormDataChange("origin_type", origin_type)}
                                                choices={augmentedJobOriginTypeOptions}
                                                errors={errors}
                                            ></BasicSelectField>
                                        )
                                    }
                                    {
                                        showJobOriginFields && valueIsDefined(job.origin_type) && parseInt(job.origin_type) === JobOriginTypes.other && (
                                            <CharField
                                                fieldName="origin_type_other_name"
                                                fieldLabel="Job Source Name"
                                                fieldValue={job.origin_type_other_name}
                                                fieldOnChange={origin_type_other_name => onFormDataChange("origin_type_other_name", origin_type_other_name)}
                                                errors={errors}
                                            ></CharField>
                                        )
                                    }
                                    {
                                        showJobOriginFields && valueIsDefined(job.origin_type) && parseInt(job.origin_type) !== JobOriginTypes.roopairs && (
                                            <CharField
                                                disabled={!allowClientDetailsEdit}
                                                fieldName="origin_id"
                                                fieldLabel={`${parseInt(job.origin_type) !== JobOriginTypes.other ? augmentedJobOriginTypeOptions.find(option => option.value === parseInt(job.origin_type)).label : (job.origin_type_other_name || "Other")} ID`}
                                                fieldValue={job.origin_id}
                                                fieldOnChange={origin_id => onFormDataChange("origin_id", origin_id)}
                                                maxLength="15"
                                                errors={errors}
                                            ></CharField>
                                        )
                                    }
                                    <DateField
                                        fieldName="date_received"
                                        fieldLabel="Date Received"
                                        fieldValue={job.date_received || ""}
                                        fieldOnChange={date_received => onFormDataChange("date_received", date_received || null)}
                                        errors={errors}
                                    />
                                    <SearchCreateSelectField
                                        fieldName="service_name"
                                        fieldLabel="Service Type"
                                        fieldValue={selectedPriceBookService ? selectedPriceBookService.description : (job.service_name || null)}
                                        inputComponent={
                                            <PriceBookServiceSearchOrCreateSelect
                                                disabled={!allowClientDetailsEdit}
                                                onSelectionChange={selectedOption => updatePriceBookServiceSelection(selectedOption != null ? selectedOption.object : null)}
                                                onInputChange={(input, action) => {action.action === "input-change" && this.setState({serviceSearchInput: input})}}
                                                defaultSelected={selectedPriceBookService ? priceBookServiceToPriceBookServiceOption(selectedPriceBookService) : (job.service_name ? {value: null, label: job.service_name} : null)}
                                                showCreateButton={true}
                                                onCreateClick={event => switchToSecondaryForm("ADD_PRICEBOOKITEM_SERVICE", null, {description: this.state.serviceSearchInput})}
                                            ></PriceBookServiceSearchOrCreateSelect>
                                        }
                                        showButton={allowClientDetailsEdit && selectedPriceBookService !== null}
                                        buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Service Type</Fragment>}
                                        buttonAction={event => switchToSecondaryForm("EDIT_PRICEBOOKITEM_SERVICE", selectedPriceBookService, null)}
                                        disabled={!allowClientDetailsEdit}
                                        errors={errors}
                                    ></SearchCreateSelectField>
                                    <SwitchField
                                        disabled={!allowJobTypeEdit}
                                        fieldName="is_job_walk"
                                        fieldLabel="Job Type"
                                        fieldValue={job.is_job_walk}
                                        fieldOnChange={is_job_walk => onFormDataChange("is_job_walk", is_job_walk)}
                                        checkedText="Job Walk"
                                        uncheckedText="Service"
                                        errors={errors}
                                    ></SwitchField>
                                    <JobEditableDetailsFields {...this.props} />
                                    <GeneratedDatetimeField
                                        fieldName="estimated_arrival_time"
                                        fieldLabel="Estimated Arrival Time"
                                        fieldValue={job.estimated_arrival_time}
                                        fieldOnChange={estimated_arrival_time => onFormDataChange("estimated_arrival_time", estimated_arrival_time || null)}
                                        errors={errors}
                                        preferredTimezone={preferredTimezone}
                                    ></GeneratedDatetimeField>

                                    {
                                        window.CURRENT_USER.service_company.feature_job_series_enabled &&
                                        window.CURRENT_USER.service_company.job_custom_id_generation_mode !== 1 &&
                                        jobDoesNotHaveParent &&
                                        (job.series_origin === null || job.series_origin === undefined) &&
                                        job.estimated_arrival_time && (
                                            <BooleanField
                                                fieldName="recurrence_enabled"
                                                fieldLabel=""
                                                fieldValue={job.recurrence_enabled}
                                                fieldOnChange={recurrence_enabled => {
                                                    let todayDay = dayjs(job.estimated_arrival_time).day()
                                                    todayDay = todayDay === 0 ? 6 : todayDay - 1

                                                    onFormDataChange("recurrence_enabled", recurrence_enabled)
                                                    onFormDataChange("recurrence_interval", recurrence_enabled ? 1 : null)
                                                    onFormDataChange("recurrence_frequency", recurrence_enabled ? RecurrenceFrequency.MONTHLY : null)
                                                    onFormDataChange("weekly_recurrence", recurrence_enabled ? [todayDay] : null)
                                                    onFormDataChange("monthly_recurrence", recurrence_enabled ? "0" : null)
                                                }}
                                                inputLabel="Create a series from this Job"
                                                errors={errors}
                                            ></BooleanField>
                                        )
                                    }
                                    {job.recurrence_enabled && (
                                        <>
                                            <DateField
                                                fieldName="recurrence_end"
                                                fieldLabel="Series End Date"
                                                fieldValue={dayjs(job.recurrence_end).format("YYYY-MM-DD")}
                                                fieldOnChange={recurrence_end => onFormDataChange("recurrence_end", recurrence_end || null)}
                                                errors={errors}
                                            ></DateField>
                                            <HorizontalLayout>
                                                <IntegerField
                                                    fieldName="recurrence_interval"
                                                    fieldLabel="Repeat Every"
                                                    fieldValue={job.recurrence_interval}
                                                    fieldOnChange={recurrence_interval => onFormDataChange("recurrence_interval", parseInt(recurrence_interval))}
                                                    errors={errors}
                                                ></IntegerField>
                                                <BasicSelectField
                                                    fieldName="recurrence_frequency"
                                                    fieldLabel={'\u2009'}
                                                    fieldValue={job.recurrence_frequency}
                                                    fieldOnChange={recurrence_frequency => onFormDataChange("recurrence_frequency", recurrence_frequency)}
                                                    choices={frequencyOptions}
                                                    errors={errors}
                                                ></BasicSelectField>
                                            </HorizontalLayout>
                                        </>
                                    )}
                                    {job.recurrence_frequency == RecurrenceFrequency.WEEKLY && (
                                        <AdvancedMultiSelectField
                                            fieldName="weekly_recurrence"
                                            fieldLabel="Repeat On"
                                            fieldValue={getDefaultRecurrenceWeekdays(job, weeklyRecurrenceOptions)}
                                            fieldOnChange={(selectedOptions) => onFormDataChange("weekly_recurrence", selectedOptions ?? [])}
                                            choices={weeklyRecurrenceOptions}
                                            errors={errors}
                                        />
                                    )}
                                    {job.recurrence_frequency == RecurrenceFrequency.MONTHLY && (
                                        <BasicSelectField
                                            fieldName="monthly_recurrence"
                                            fieldLabel="Repeat On"
                                            fieldValue={job.monthly_recurrence}
                                            fieldOnChange={monthly_recurrence => onFormDataChange("monthly_recurrence", monthly_recurrence)}
                                            choices={monthlyRecurrenceOptions}
                                            errors={errors}
                                        />
                                    )}

                                    {
                                        job.recurrence_enabled && job.estimated_arrival_time !== null && job.recurrence_end !== null && job.recurrence_interval !== null && job.recurrence_frequency !== null && (
                                            <JobSeriesDates job={job} />
                                        )
                                    }

                                    <DurationField
                                        key={`duration_${job.service_name}`}
                                        fieldName="estimated_duration"
                                        fieldLabel="Estimated Job Duration"
                                        fieldValue={job.estimated_duration || ""}
                                        fieldOnChange={estimated_duration => onFormDataChange("estimated_duration", estimated_duration || null)}
                                        unit="hours"
                                        unitMultiplier={3600}
                                        errors={errors}
                                    ></DurationField>
                                    <AssignedTechniciansField
                                        fieldName="assigned_technicians"
                                        fieldLabel="Assigned Technician(s)"
                                        fieldValue={getDefaultAssignedTechnicians(job, workingTechnicianOptions)}
                                        fieldOnChange={assigned_technicians => onFormDataChange("assigned_technicians", assigned_technicians || [])}
                                        choices={workingTechnicianOptions}
                                        placeholder="Select technicians..."
                                        noOptionsMessage="No Technicians Found"
                                        errors={errors}
                                    ></AssignedTechniciansField>
                                    {
                                        similarJobs.length !== 0 && !window.CURRENT_USER.hide_financial_context && (
                                            <Fragment>
                                                <Banner type="info" text="There are similar active or upcoming jobs already created for this service location." />
                                                <LinkedObjectsDisplayField
                                                    fieldName="similar_jobs"
                                                    fieldLabel="Similar Jobs"
                                                    objectComponentList={similarJobs.map((job, index) => <JobObjectLink key={`similar_jobs_${index}`} job={job} destination={DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, job.id)}></JobObjectLink>)}
                                                ></LinkedObjectsDisplayField>
                                            </Fragment>
                                        )
                                    }
                                </fieldset>
                            )
                        }
                    </div>
                    {selectedClient !== null && selectedServiceLocation !== null && this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default JobForm;
