import React from 'react';
import ObjectListCard from '../../core/components/ObjectListCard';


function ClientListCard(props) {
    const { data } = props
    const client = data

    const footerContext = {
        label: null,
        value: null
    }

    return <ObjectListCard
        objectName="Client"
        objectID={null}
        showState={false}
        stateLabel=""
        headerContext={null}
        statusChip={null}
        bodyTitle={client.name}
        bodySubtextPrimary={client.service_locations_display}
        bodySubtextSecondary={null}
        footerContextLabel={footerContext.label}
        footerContextValue={footerContext.value}
        footerPrimary={null}
    />
}

export default ClientListCard;
